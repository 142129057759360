@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.17123rem);
  }
}
.margin-top-2 {
  margin-top: 0.01712rem;
}

.padding-top-2 {
  padding-top: 0.01712rem;
}

.margin-bottom-2 {
  margin-bottom: 0.01712rem;
}

.padding-bottom-2 {
  padding-bottom: 0.01712rem;
}

.margin-left-2 {
  margin-left: 0.01712rem;
}

.padding-left-2 {
  padding-left: 0.01712rem;
}

.margin-right-2 {
  margin-right: 0.01712rem;
}

.padding-right-2 {
  padding-right: 0.01712rem;
}

.margin-top-3 {
  margin-top: 0.02568rem;
}

.padding-top-3 {
  padding-top: 0.02568rem;
}

.margin-bottom-3 {
  margin-bottom: 0.02568rem;
}

.padding-bottom-3 {
  padding-bottom: 0.02568rem;
}

.margin-left-3 {
  margin-left: 0.02568rem;
}

.padding-left-3 {
  padding-left: 0.02568rem;
}

.margin-right-3 {
  margin-right: 0.02568rem;
}

.padding-right-3 {
  padding-right: 0.02568rem;
}

.margin-top-4 {
  margin-top: 0.03425rem;
}

.padding-top-4 {
  padding-top: 0.03425rem;
}

.margin-bottom-4 {
  margin-bottom: 0.03425rem;
}

.padding-bottom-4 {
  padding-bottom: 0.03425rem;
}

.margin-left-4 {
  margin-left: 0.03425rem;
}

.padding-left-4 {
  padding-left: 0.03425rem;
}

.margin-right-4 {
  margin-right: 0.03425rem;
}

.padding-right-4 {
  padding-right: 0.03425rem;
}

.margin-top-5 {
  margin-top: 0.04281rem;
}

.padding-top-5 {
  padding-top: 0.04281rem;
}

.margin-bottom-5 {
  margin-bottom: 0.04281rem;
}

.padding-bottom-5 {
  padding-bottom: 0.04281rem;
}

.margin-left-5 {
  margin-left: 0.04281rem;
}

.padding-left-5 {
  padding-left: 0.04281rem;
}

.margin-right-5 {
  margin-right: 0.04281rem;
}

.padding-right-5 {
  padding-right: 0.04281rem;
}

.margin-top-6 {
  margin-top: 0.05137rem;
}

.padding-top-6 {
  padding-top: 0.05137rem;
}

.margin-bottom-6 {
  margin-bottom: 0.05137rem;
}

.padding-bottom-6 {
  padding-bottom: 0.05137rem;
}

.margin-left-6 {
  margin-left: 0.05137rem;
}

.padding-left-6 {
  padding-left: 0.05137rem;
}

.margin-right-6 {
  margin-right: 0.05137rem;
}

.padding-right-6 {
  padding-right: 0.05137rem;
}

.margin-top-7 {
  margin-top: 0.05993rem;
}

.padding-top-7 {
  padding-top: 0.05993rem;
}

.margin-bottom-7 {
  margin-bottom: 0.05993rem;
}

.padding-bottom-7 {
  padding-bottom: 0.05993rem;
}

.margin-left-7 {
  margin-left: 0.05993rem;
}

.padding-left-7 {
  padding-left: 0.05993rem;
}

.margin-right-7 {
  margin-right: 0.05993rem;
}

.padding-right-7 {
  padding-right: 0.05993rem;
}

.margin-top-8 {
  margin-top: 0.06849rem;
}

.padding-top-8 {
  padding-top: 0.06849rem;
}

.margin-bottom-8 {
  margin-bottom: 0.06849rem;
}

.padding-bottom-8 {
  padding-bottom: 0.06849rem;
}

.margin-left-8 {
  margin-left: 0.06849rem;
}

.padding-left-8 {
  padding-left: 0.06849rem;
}

.margin-right-8 {
  margin-right: 0.06849rem;
}

.padding-right-8 {
  padding-right: 0.06849rem;
}

.margin-top-10 {
  margin-top: 0.08562rem;
}

.padding-top-10 {
  padding-top: 0.08562rem;
}

.margin-bottom-10 {
  margin-bottom: 0.08562rem;
}

.padding-bottom-10 {
  padding-bottom: 0.08562rem;
}

.margin-left-10 {
  margin-left: 0.08562rem;
}

.padding-left-10 {
  padding-left: 0.08562rem;
}

.margin-right-10 {
  margin-right: 0.08562rem;
}

.padding-right-10 {
  padding-right: 0.08562rem;
}

.margin-top-12 {
  margin-top: 0.10274rem;
}

.padding-top-12 {
  padding-top: 0.10274rem;
}

.margin-bottom-12 {
  margin-bottom: 0.10274rem;
}

.padding-bottom-12 {
  padding-bottom: 0.10274rem;
}

.margin-left-12 {
  margin-left: 0.10274rem;
}

.padding-left-12 {
  padding-left: 0.10274rem;
}

.margin-right-12 {
  margin-right: 0.10274rem;
}

.padding-right-12 {
  padding-right: 0.10274rem;
}

.margin-top-15 {
  margin-top: 0.12842rem;
}

.padding-top-15 {
  padding-top: 0.12842rem;
}

.margin-bottom-15 {
  margin-bottom: 0.12842rem;
}

.padding-bottom-15 {
  padding-bottom: 0.12842rem;
}

.margin-left-15 {
  margin-left: 0.12842rem;
}

.padding-left-15 {
  padding-left: 0.12842rem;
}

.margin-right-15 {
  margin-right: 0.12842rem;
}

.padding-right-15 {
  padding-right: 0.12842rem;
}

.margin-top-16 {
  margin-top: 0.13699rem;
}

.padding-top-16 {
  padding-top: 0.13699rem;
}

.margin-bottom-16 {
  margin-bottom: 0.13699rem;
}

.padding-bottom-16 {
  padding-bottom: 0.13699rem;
}

.margin-left-16 {
  margin-left: 0.13699rem;
}

.padding-left-16 {
  padding-left: 0.13699rem;
}

.margin-right-16 {
  margin-right: 0.13699rem;
}

.padding-right-16 {
  padding-right: 0.13699rem;
}

.margin-top-18 {
  margin-top: 0.15411rem;
}

.padding-top-18 {
  padding-top: 0.15411rem;
}

.margin-bottom-18 {
  margin-bottom: 0.15411rem;
}

.padding-bottom-18 {
  padding-bottom: 0.15411rem;
}

.margin-left-18 {
  margin-left: 0.15411rem;
}

.padding-left-18 {
  padding-left: 0.15411rem;
}

.margin-right-18 {
  margin-right: 0.15411rem;
}

.padding-right-18 {
  padding-right: 0.15411rem;
}

.margin-top-20 {
  margin-top: 0.17123rem;
}

.padding-top-20 {
  padding-top: 0.17123rem;
}

.margin-bottom-20 {
  margin-bottom: 0.17123rem;
}

.padding-bottom-20 {
  padding-bottom: 0.17123rem;
}

.margin-left-20 {
  margin-left: 0.17123rem;
}

.padding-left-20 {
  padding-left: 0.17123rem;
}

.margin-right-20 {
  margin-right: 0.17123rem;
}

.padding-right-20 {
  padding-right: 0.17123rem;
}

.margin-top-24 {
  margin-top: 0.20548rem;
}

.padding-top-24 {
  padding-top: 0.20548rem;
}

.margin-bottom-24 {
  margin-bottom: 0.20548rem;
}

.padding-bottom-24 {
  padding-bottom: 0.20548rem;
}

.margin-left-24 {
  margin-left: 0.20548rem;
}

.padding-left-24 {
  padding-left: 0.20548rem;
}

.margin-right-24 {
  margin-right: 0.20548rem;
}

.padding-right-24 {
  padding-right: 0.20548rem;
}

.margin-top-25 {
  margin-top: 0.21404rem;
}

.padding-top-25 {
  padding-top: 0.21404rem;
}

.margin-bottom-25 {
  margin-bottom: 0.21404rem;
}

.padding-bottom-25 {
  padding-bottom: 0.21404rem;
}

.margin-left-25 {
  margin-left: 0.21404rem;
}

.padding-left-25 {
  padding-left: 0.21404rem;
}

.margin-right-25 {
  margin-right: 0.21404rem;
}

.padding-right-25 {
  padding-right: 0.21404rem;
}

.margin-top-28 {
  margin-top: 0.23973rem;
}

.padding-top-28 {
  padding-top: 0.23973rem;
}

.margin-bottom-28 {
  margin-bottom: 0.23973rem;
}

.padding-bottom-28 {
  padding-bottom: 0.23973rem;
}

.margin-left-28 {
  margin-left: 0.23973rem;
}

.padding-left-28 {
  padding-left: 0.23973rem;
}

.margin-right-28 {
  margin-right: 0.23973rem;
}

.padding-right-28 {
  padding-right: 0.23973rem;
}

.margin-top-30 {
  margin-top: 0.25685rem;
}

.padding-top-30 {
  padding-top: 0.25685rem;
}

.margin-bottom-30 {
  margin-bottom: 0.25685rem;
}

.padding-bottom-30 {
  padding-bottom: 0.25685rem;
}

.margin-left-30 {
  margin-left: 0.25685rem;
}

.padding-left-30 {
  padding-left: 0.25685rem;
}

.margin-right-30 {
  margin-right: 0.25685rem;
}

.padding-right-30 {
  padding-right: 0.25685rem;
}

.margin-top-32 {
  margin-top: 0.27397rem;
}

.padding-top-32 {
  padding-top: 0.27397rem;
}

.margin-bottom-32 {
  margin-bottom: 0.27397rem;
}

.padding-bottom-32 {
  padding-bottom: 0.27397rem;
}

.margin-left-32 {
  margin-left: 0.27397rem;
}

.padding-left-32 {
  padding-left: 0.27397rem;
}

.margin-right-32 {
  margin-right: 0.27397rem;
}

.padding-right-32 {
  padding-right: 0.27397rem;
}

.margin-top-35 {
  margin-top: 0.29966rem;
}

.padding-top-35 {
  padding-top: 0.29966rem;
}

.margin-bottom-35 {
  margin-bottom: 0.29966rem;
}

.padding-bottom-35 {
  padding-bottom: 0.29966rem;
}

.margin-left-35 {
  margin-left: 0.29966rem;
}

.padding-left-35 {
  padding-left: 0.29966rem;
}

.margin-right-35 {
  margin-right: 0.29966rem;
}

.padding-right-35 {
  padding-right: 0.29966rem;
}

.margin-top-40 {
  margin-top: 0.34247rem;
}

.padding-top-40 {
  padding-top: 0.34247rem;
}

.margin-bottom-40 {
  margin-bottom: 0.34247rem;
}

.padding-bottom-40 {
  padding-bottom: 0.34247rem;
}

.margin-left-40 {
  margin-left: 0.34247rem;
}

.padding-left-40 {
  padding-left: 0.34247rem;
}

.margin-right-40 {
  margin-right: 0.34247rem;
}

.padding-right-40 {
  padding-right: 0.34247rem;
}

.margin-top-50 {
  margin-top: 0.42808rem;
}

.padding-top-50 {
  padding-top: 0.42808rem;
}

.margin-bottom-50 {
  margin-bottom: 0.42808rem;
}

.padding-bottom-50 {
  padding-bottom: 0.42808rem;
}

.margin-left-50 {
  margin-left: 0.42808rem;
}

.padding-left-50 {
  padding-left: 0.42808rem;
}

.margin-right-50 {
  margin-right: 0.42808rem;
}

.padding-right-50 {
  padding-right: 0.42808rem;
}

.margin-top-60 {
  margin-top: 0.5137rem;
}

.padding-top-60 {
  padding-top: 0.5137rem;
}

.margin-bottom-60 {
  margin-bottom: 0.5137rem;
}

.padding-bottom-60 {
  padding-bottom: 0.5137rem;
}

.margin-left-60 {
  margin-left: 0.5137rem;
}

.padding-left-60 {
  padding-left: 0.5137rem;
}

.margin-right-60 {
  margin-right: 0.5137rem;
}

.padding-right-60 {
  padding-right: 0.5137rem;
}

.margin-top-70 {
  margin-top: 0.59932rem;
}

.padding-top-70 {
  padding-top: 0.59932rem;
}

.margin-bottom-70 {
  margin-bottom: 0.59932rem;
}

.padding-bottom-70 {
  padding-bottom: 0.59932rem;
}

.margin-left-70 {
  margin-left: 0.59932rem;
}

.padding-left-70 {
  padding-left: 0.59932rem;
}

.margin-right-70 {
  margin-right: 0.59932rem;
}

.padding-right-70 {
  padding-right: 0.59932rem;
}

.margin-top-100 {
  margin-top: 0.85616rem;
}

.padding-top-100 {
  padding-top: 0.85616rem;
}

.margin-bottom-100 {
  margin-bottom: 0.85616rem;
}

.padding-bottom-100 {
  padding-bottom: 0.85616rem;
}

.margin-left-100 {
  margin-left: 0.85616rem;
}

.padding-left-100 {
  padding-left: 0.85616rem;
}

.margin-right-100 {
  margin-right: 0.85616rem;
}

.padding-right-100 {
  padding-right: 0.85616rem;
}

.w60 {
  width: 0.5137rem;
}

.minw60 {
  min-width: 0.5137rem;
}

.w70 {
  width: 0.59932rem;
}

.minw70 {
  min-width: 0.59932rem;
}

.w80 {
  width: 0.68493rem;
}

.minw80 {
  min-width: 0.68493rem;
}

.w90 {
  width: 0.77055rem;
}

.minw90 {
  min-width: 0.77055rem;
}

.w100 {
  width: 0.85616rem;
}

.minw100 {
  min-width: 0.85616rem;
}

.w110 {
  width: 0.94178rem;
}

.minw110 {
  min-width: 0.94178rem;
}

.w120 {
  width: 1.0274rem;
}

.minw120 {
  min-width: 1.0274rem;
}

.w130 {
  width: 1.11301rem;
}

.minw130 {
  min-width: 1.11301rem;
}

.w140 {
  width: 1.19863rem;
}

.minw140 {
  min-width: 1.19863rem;
}

.w150 {
  width: 1.28425rem;
}

.minw150 {
  min-width: 1.28425rem;
}

.w160 {
  width: 1.36986rem;
}

.minw160 {
  min-width: 1.36986rem;
}

.w170 {
  width: 1.45548rem;
}

.minw170 {
  min-width: 1.45548rem;
}

.w180 {
  width: 1.5411rem;
}

.minw180 {
  min-width: 1.5411rem;
}

.w190 {
  width: 1.62671rem;
}

.minw190 {
  min-width: 1.62671rem;
}

.w200 {
  width: 1.71233rem;
}

.minw200 {
  min-width: 1.71233rem;
}

.w300 {
  width: 2.56849rem;
}

.minw300 {
  min-width: 2.56849rem;
}

.w400 {
  width: 3.42466rem;
}

.minw400 {
  min-width: 3.42466rem;
}

.w500 {
  width: 4.28082rem;
}

.minw500 {
  min-width: 4.28082rem;
}

.w600 {
  width: 5.13699rem;
}

.minw600 {
  min-width: 5.13699rem;
}

.w700 {
  width: 5.99315rem;
}

.minw700 {
  min-width: 5.99315rem;
}

.w800 {
  width: 6.84932rem;
}

.minw800 {
  min-width: 6.84932rem;
}

.w900 {
  width: 7.70548rem;
}

.minw900 {
  min-width: 7.70548rem;
}

.w1000 {
  width: 8.56164rem;
}

.minw1000 {
  min-width: 8.56164rem;
}

.w265 {
  width: 2.26884rem;
}

.font10 {
  font-size: 0.08562rem;
}

.font12 {
  font-size: 0.10274rem;
}

.font13 {
  font-size: 0.1113rem;
}

.font14 {
  font-size: 0.11986rem;
}

.font16 {
  font-size: 0.13699rem;
}

.font18 {
  font-size: 0.15411rem;
}

.font20 {
  font-size: 0.17123rem;
}

.font22 {
  font-size: 0.18836rem;
}

.font24 {
  font-size: 0.20548rem;
}

.font26 {
  font-size: 0.2226rem;
}

.font28 {
  font-size: 0.23973rem;
}

.font30 {
  font-size: 0.25685rem;
}

.font32 {
  font-size: 0.27397rem;
}

.font34 {
  font-size: 0.2911rem;
}

.font36 {
  font-size: 0.30822rem;
}

.font38 {
  font-size: 0.32534rem;
}

.font40 {
  font-size: 0.34247rem;
}

:root {
  --white: #fff;
  --black: #000;
  --grey: #5e5e5e;
  --red: #db001c;
  --dark-grey-color: #6b7694;
  --grey-border: #777995;
  --base-grey: #09002a;
  --golden-color: #f4cf00;
  --pale-grey-color: #a5a7c2;
  --main-color: #1a90ff;
  --base-gutter: 0.08562rem;
  --medium-gutter: 0.13699rem;
  --modalz-index: 9999;
  --tipz-index: 999;
  --image-placeholder-color: rgba(0, 0, 0, 0.5);
  --header-title-font-size: 0.20548rem;
  --game-title-font-size: 0.17123rem;
  --screen-left: 0.42808rem;
  --screen-right: 0.77055rem;
  --screen-right-second: 0.32534rem;
}

.lighter {
  --primary-color: #1a9fffff;
  --success-color: #7fcc66ff;
  --warning-color: #ffd500ff;
  --danger-color: #ff3333ff;
  --pink-color: #e83e98;
  --reverse-color: #fff;
  --text-color: #f7faff;
  --scroll-color: rgb(153 153 153 /50%);
  --scroll-hover-color: rgb(153 153 153 /80%);
  --base-border-radius: 0.05993rem;
  --medium-border-radius: 0.10274rem;
  --larger-border-radius: 0.13699rem;
  --bg-color: transparent;
  --bg-color-from: transparent;
  --bg-color-to: transparent;
  --modal-bg-color: #15446b;
  --modal-bg-color-from: #15446b;
  --modal-bg-color-to: #b6436a;
  --modal-border-color: #b6b8cd;
}

.fade-enter,
.fade-appear {
  opacity: 0;
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fade-exit-done {
  opacity: 0;
}

.container .bannerInfo {
  padding-top: 0.78767rem;
  padding-bottom: 0.87329rem;
}
.container .bannerList {
  overflow: hidden;
  margin: -0.42808rem -0.19692rem 0;
  padding: 0.42808rem 0.19692rem 0;
}
.container .bannerList :global .swiper {
  overflow: visible !important;
}
.container .bannerList :global .game-carousel-section-carouselItem {
  text-align: center;
}
.container .bannerList :global .game-carousel-section-carouselItem .gameItemOverlayContainer {
  -webkit-mask-size: cover;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMzIiBoZWlnaHQ9IjEzMyIgdmlld0JveD0iMCAwIDEzMyAxMzMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjMwODU5NCA2MC4xMDQ1QzAuMzA4NTk0IDMxLjgyMDIgMC4zMDg1OTQgMTcuNjc4MSA5LjA5NTM5IDguODkxMjlDMTcuODgyMiAwLjEwNDQ5MiAzMi4wMjQzIDAuMTA0NDkyIDYwLjMwODYgMC4xMDQ0OTJINzIuMzA4NkMxMDAuNTkzIDAuMTA0NDkyIDExNC43MzUgMC4xMDQ0OTIgMTIzLjUyMiA4Ljg5MTI5QzEzMi4zMDkgMTcuNjc4MSAxMzIuMzA5IDMxLjgyMDIgMTMyLjMwOSA2MC4xMDQ1VjcyLjEwNDVDMTMyLjMwOSAxMDAuMzg5IDEzMi4zMDkgMTE0LjUzMSAxMjMuNTIyIDEyMy4zMThDMTE0LjczNSAxMzIuMTA0IDEwMC41OTMgMTMyLjEwNCA3Mi4zMDg2IDEzMi4xMDRINjAuMzA4NkMzMi4wMjQzIDEzMi4xMDQgMTcuODgyMiAxMzIuMTA0IDkuMDk1MzkgMTIzLjMxOEMwLjMwODU5NCAxMTQuNTMxIDAuMzA4NTk0IDEwMC4zODkgMC4zMDg1OTQgNzIuMTA0NVY2MC4xMDQ1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}
.container .bannerList :global .game-carousel-section-activeItem .gameItemOverlayContainer::after {
  border-radius: 23%;
}
.container .skeletonContainer {
  overflow: hidden;
  margin: -0.42808rem -0.19692rem 0;
  padding: 0.42808rem 0.19692rem 0;
}